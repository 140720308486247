import { useQuery } from '@tanstack/vue-query';
import * as Sentry from '@sentry/vue';

import {
	type searchNode,
	type SearchSuggestionsResponse,
	SearchSuggestionsSchema,
} from '~/types/search.ts';
import { validatorFactory } from '@laam/lib/validator';

const searchSuggestionsValidator = validatorFactory<SearchSuggestionsResponse>(
	SearchSuggestionsSchema,
);

export const useSearchSuggestions = (
	searchQuery: Ref<string>,
	searchNode: Ref<searchNode>,
	enableSearchQuery: Ref<boolean>,
) => {
	const enabled = computed(
		() =>
			enableSearchQuery.value &&
			!!searchQuery.value &&
			searchQuery.value.length >= 3,
	);
	const { data, isLoading } = useQuery({
		queryKey: ['search_query', searchQuery, 'searchNode', searchNode],
		retry: 2,
		enabled,
		queryFn: async () => {
			try {
				const api = createApiInstance();
				const response = await api.get(
					`/v1/suggestions?query=${searchQuery.value}${searchNode.value.id ? `&node_id=${searchNode.value.id}` : ''}`,
				);

				if (response.status === 200) {
					try {
						return searchSuggestionsValidator.verify(response.data);
					} catch (e) {
						const err = new Error('Failed to verify search suggestions data');
						Sentry.captureException(err, (scope) => {
							scope.setContext('errors', {
								error: e,
							});
							return scope;
						});
						console.error('Failed to verify rewards signup data', e);
						throw err;
					}
				}
				const error = new Error('Failed to fetch search suggestions data');
				Sentry.captureException(error, (scope) => {
					scope.setContext('response', response.data);
					return scope;
				});
				throw error;
			} catch (error: any) {
				console.error('Failed to fetch search suggestions data', error);
				Sentry.captureException(error);
				throw new Error(error.message);
			}
		},
	});

	return {
		data,
		isLoading,
	};
};
