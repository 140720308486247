<script setup lang="ts">
import { PhCaretDown, PhCaretUp, PhCheck } from '@phosphor-icons/vue';
import { SEARCH_NODES } from '~/utils/enums.ts';
import { type searchNode } from '~/types/search.ts';
import { useRouter } from 'vue-router';

const router = useRouter();
const { $eventClient } = useNuxtApp();

const isSearchNodesOpen = inject('isSearchNodesOpen') as Ref<boolean>;
const selectedNode = inject('selectedNode') as Ref<searchNode>;
const searchNodesRef = inject('searchNodesRef') as Ref<HTMLDivElement | null>;

const handleItemClick = (val: searchNode) => {
	isSearchNodesOpen.value = false;
	selectedNode.value = val;

	$eventClient.sendEvent('search-node-click', {
		nodeType: selectedNode.value.name,
		nodeId: selectedNode.value.id,
	});

	if (router.currentRoute.value.name === 'search') {
		const updatedQuery = {
			...router.currentRoute.value.query,
		};
		if (val.id) {
			updatedQuery.node_id = val.id.toString();
		} else {
			delete updatedQuery.node_id;
		}

		router.replace({ name: 'search', query: updatedQuery });
	}
};

const handleTrigger = () => {
	isSearchNodesOpen.value = !isSearchNodesOpen.value;
};

const handleClickOutside = (e: MouseEvent) => {
	if (
		searchNodesRef?.value &&
		!searchNodesRef.value.contains(e.target as Node)
	) {
		isSearchNodesOpen.value = false;
	}
};

onMounted(() => {
	document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
	document.removeEventListener('click', handleClickOutside);
});
</script>

<template>
	<div ref="searchNodesRef">
		<div
			class="cursor-pointer select-none flex items-center bg-gray-100 max-h-9xl p-lg gap-md rounded-medium border border-gray-200 justify-between rounded-r-none"
			@click.stop.prevent="handleTrigger"
		>
			<div class="text-sm">
				{{ selectedNode.name }}
			</div>
			<PhCaretDown v-if="!isSearchNodesOpen" size="16" />
			<PhCaretUp v-else size="16" />
		</div>

		<div
			v-if="isSearchNodesOpen"
			class="lg:max-w-[8vw] z-[40] w-[30vw] select-none mt-md absolute bg-white py-sm px-md border border-gray-200 rounded-xs shadow-md"
		>
			<div
				v-for="(item, index) in SEARCH_NODES"
				:key="index"
				class="flex justify-between hover:bg-gray-100 rounded-xs p-md"
				@click.stop.prevent="handleItemClick(item)"
			>
				<p class="text-md text-gray-900">{{ item.name }}</p>
				<PhCheck v-if="selectedNode.name === item.name" size="20" />
			</div>
		</div>
	</div>
</template>
