<script setup lang="ts">
import { PhMagnifyingGlass } from '@phosphor-icons/vue';
import type { RecentSearchesResponse } from '~/types/search.ts';

interface Props {
	searchesData: RecentSearchesResponse;
}

defineProps<Props>();

const { $eventClient } = useNuxtApp();

const searchQuery: Ref<string> = inject('debouncedSearchQuery')!;
const handleFocusOut: () => void = inject('handleFocusOut')!;

const handleSearchQuery = async (query: string) => {
	$eventClient.sendEvent('recent-search-click', {
		searchQuery: query,
		type: 'recent searches',
		href: query,
	});

	handleFocusOut();
	navigateTo(`/search?search=${query}`);
};
</script>

<template>
	<div v-if="searchQuery.length < 2" class="flex flex-col lg:px-3xl">
		<h1 class="py-xl text-gray-500 text-sm font-semibold">Recent Searches</h1>
		<div class="flex flex-col gap-xs">
			<div
				v-for="(query, idx) in searchesData.slice(0, 5)"
				:key="idx"
				class="lg:px-lg py-md flex items-center gap-xl hover:bg-gray-50 cursor-pointer rounded-xs"
				@click="() => handleSearchQuery(query)"
			>
				<PhMagnifyingGlass size="20" class="text-gray-400" />
				<span class="text-xs">
					{{ query }}
				</span>
			</div>
		</div>
	</div>
</template>
