<script setup lang="ts">
import { IconButton } from '@laam/ui/base';
import { PhMagnifyingGlass } from '@phosphor-icons/vue';
import type { BannerReasons } from '~/components/TopBanner.vue';
const { $cathodeClient, $eventClient } = useNuxtApp();

const handleSearchClickEvent = () => {
	$cathodeClient.queueEvent('search_click', {
		source: window.location.href,
		type: 'text search',
		time: new Date().toUTCString(),
	});

	$eventClient.sendEvent('mobile-search-clicked', {
		url: window.location.href,
	});
};

const isMobileSearchOpen = ref(false);
const topBannerReason = inject('topBannerReason') as Ref<BannerReasons>;

const GlobalStore = useGlobalStore();
const { storeType } = storeToRefs(GlobalStore);

const handleSearchClick = () => {
	if (storeType.value === 'OCTANE') {
		navigateTo('/search');
	} else {
		isMobileSearchOpen.value = true;
	}

	handleSearchClickEvent();
};

provide('isMobileSearchOpen', isMobileSearchOpen);

watch(isMobileSearchOpen, (newVal) => {
	if (newVal) {
		document.body.style.overflow = 'hidden';
	} else {
		document.body.style.overflow = 'auto';
	}
});

onBeforeUnmount(() => {
	document.body.style.overflow = 'auto';
});
</script>

<template>
	<IconButton
		v-if="!isMobileSearchOpen || storeType === 'OCTANE'"
		size="nav-button"
		variant="icon"
		class="laam-search-button"
		@click="handleSearchClick"
	>
		<PhMagnifyingGlass />
	</IconButton>

	<div
		v-else-if="storeType !== 'OCTANE'"
		class="inset-0 z-[20] w-full h-full fixed bg-gray-100"
		:class="{
			'top-[calc(var(--laam-shipping-banner-height))]': topBannerReason,
		}"
	>
		<SearchHeader />
	</div>
</template>
