import { type Static, Type } from '@sinclair/typebox';

export interface searchNode {
	name: string;
	id: number | null;
}

const SuggestedBrands = Type.Array(
	Type.Object({
		name: Type.String(),
		cover_image: Type.String(),
		href: Type.String(),
	}),
);

export type SuggestedBrandsType = Static<typeof SuggestedBrands>;

const SuggestedCategories = Type.Array(
	Type.Object({
		suggestion: Type.String(),
		href: Type.String(),
	}),
);

export const TrendingSearches = Type.Array(
	Type.Object({
		term: Type.String(),
		href: Type.Optional(Type.String()),
	}),
);

const SuggestedSearches = Type.Array(Type.String());

export const SearchSuggestionsSchema = Type.Object({
	brands: SuggestedBrands,
	categories: SuggestedCategories,
	others: SuggestedSearches,
});

export const RecentSearches = Type.Array(Type.Optional(Type.String()));

//TODO: fix naming convention here
export type SuggestedCategoriesType = Static<typeof SuggestedCategories>;
export type TrendingSearchesType = Static<typeof TrendingSearches>;
export type SuggestedSearchesType = Static<typeof SuggestedSearches>;
export type SearchSuggestionsResponse = Static<typeof SearchSuggestionsSchema>;
export type RecentSearchesResponse = Static<typeof RecentSearches>;
